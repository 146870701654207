import React from 'react'
import PropTypes from 'prop-types'
import ShortHorizontalLine from '../../atoms/ShortHorizontalLine/ShortHorizontalLine'
import './HowItWorks.sass'
import HorizontalImageSlide from '../../molecules/HorizontalImageSlide/HorizontalImageSlide'
import IllustratedPoint from '../../molecules/IllustratedPoint/IllustratedPoint'
import VerticalImageSlide from '../../molecules/VerticalImageSlide/VerticalImageSlide'

const HowItWorks = ({
  hasGreyBackground,
  title,
  items,
  verticalSlides,
  smallPoints,
}) => {
  const sectionClasses = ['section', 'is-medium']
  if (hasGreyBackground) {
    sectionClasses.push('grey-background')
  }
  return (
    <>
      <section className={sectionClasses.join(' ')}>
        <div className="container">
          {title && (
            <div className="columns">
              <div className="column is-full how-it-works-title">
                <h2 className="has-text-centered">{title}</h2>
                <ShortHorizontalLine className="my-4" isCentered />
              </div>
            </div>
          )}
          <div className="columns is-multiline is-vcentered">
            {items.map((item, index) => (
              <HorizontalImageSlide
                title={item.title}
                description={item.description}
                image={item.image}
                isImageFirst={index % 2 === 0}
                slideNumber={index + 1}
                withMarginBottom
              />
            ))}
          </div>
          <div className="columns is-multiline">
            {verticalSlides.map((item, index) => (
              <VerticalImageSlide
                description={item.description}
                title={item.title}
                image={item.image}
              />
            ))}
          </div>
        </div>
      </section>
      <section className="section is-medium grey-background">
        <div className="container">
          <div className="columns is-multiline">
            <div className="column is-12 has-text-centered mb-5">
              <h2>And there’s more!</h2>
            </div>
            {smallPoints.map((item) => (
              <IllustratedPoint
                description={item.description}
                title={item.title}
                image={item.image}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  )
}
HowItWorks.propTypes = {
  hasGreyBackground: PropTypes.bool,
  title: PropTypes.string,
  smallPoints: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
      }),
    })
  ),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
      }),
    })
  ),
  verticalSlides: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
      }),
    })
  ),
}

export default HowItWorks
