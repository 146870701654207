import React from 'react'
import PropTypes from 'prop-types'
import NumberInCircle from '../../atoms/NumberInCircle/NumberInCircle'

const Image = ({ src, alt }) => {
  return (
    <div className="column is-6-desktop is-10-tablet is-offset-0-desktop is-12-mobile">
      <figure className="image">
        <img
          src={!!src.childImageSharp ? src.childImageSharp.fluid.src : src}
          alt={alt}
        />
      </figure>
    </div>
  )
}

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
}

const DescriptionBlock = ({ title, number, description, isFirst }) => {
  const wrapperClasses = [
    'column is-4-desktop is-10-tablet is-offset-0-desktop is-12-mobile',
  ]
  const descriptionClasses = ['body-small has-text-centered-touch']
  const titleClasses = ['my-4 has-text-centered-touch']
  const circleClasses = ['has-text-centered-touch']
  if (isFirst) {
    wrapperClasses.push('is-offset-2-desktop')
    descriptionClasses.push('has-text-right')
    titleClasses.push('has-text-right')
    circleClasses.push('has-text-right')
  }

  return (
    <div className={wrapperClasses.join(' ')}>
      {number && (
        <div className={circleClasses.join(' ')}>
          <NumberInCircle number={number} />
        </div>
      )}
      <h3 className={titleClasses.join(' ')}>{title}</h3>
      <p className={descriptionClasses.join(' ')}>{description}</p>
    </div>
  )
}

DescriptionBlock.propTypes = {
  title: PropTypes.string,
  number: PropTypes.number,
  description: PropTypes.string,
  isFirst: PropTypes.bool,
}

const HorizontalImageSlide = ({
  isImageFirst,
  withMarginBottom,
  image,
  title,
  description,
  slideNumber,
}) => {
  const elements = [
    <Image src={image.src} alt={image.alt} />,
    <DescriptionBlock
      title={title}
      description={description}
      isFirst={!isImageFirst}
      number={slideNumber}
    />,
  ]
  const classNames = ['column is-full']
  if (withMarginBottom) {
    classNames.push('mb-75 mb-50-touch')
  }
  const elementWrapperClassNames = ['columns is-vcentered is-multiline']
  if (!isImageFirst) {
    elementWrapperClassNames.push('flex-direction-column-reverse-touch')
  } else {
    elementWrapperClassNames.push('flex-direction-column-touch')
  }
  return (
    <div className={classNames.join(' ')}>
      <div className={elementWrapperClassNames.join(' ')}>
        {isImageFirst ? elements : elements.reverse()}
      </div>
    </div>
  )
}

HorizontalImageSlide.propTypes = {
  title: PropTypes.string,
  slideNumber: PropTypes.number,
  description: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  isImageFirst: PropTypes.bool,
  withMarginBottom: PropTypes.bool,
}

export default HorizontalImageSlide
