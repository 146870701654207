import React from 'react'
import PropTypes from 'prop-types'

const ShortHorizontalLine = ({
  className,
  isCentered,
  isCenteredMobileOnly,
  isCenteredTouchOnly,
}) => {
  const wrapperClasses = ['hr-wrapper']
  if (isCentered) wrapperClasses.push('centered')
  if (isCenteredMobileOnly) wrapperClasses.push('centered-mobile')
  if (isCenteredTouchOnly) wrapperClasses.push('centered-touch')
  const computedClass = ['short-hr', className].filter((cn) => !!cn)

  return (
    <div className={wrapperClasses.join(' ')}>
      <div className={computedClass.join(' ')} />
    </div>
  )
}

ShortHorizontalLine.propTypes = {
  className: PropTypes.string,
  isCentered: PropTypes.bool,
  isCenteredTouchOnly: PropTypes.bool,
  isCenteredMobileOnly: PropTypes.bool,
}

export default ShortHorizontalLine
