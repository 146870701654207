import React from 'react'
import PropTypes from 'prop-types'
import ShortHorizontalLine from '../../atoms/ShortHorizontalLine/ShortHorizontalLine'

const MainHero = ({
  hasGreyBackground,
  hasDarkBackground,
  title,
  subtitle,
  image,
}) => {
  const sectionClasses = ['section', 'is-medium', 'fancy-background']
  if (hasGreyBackground) {
    sectionClasses.push('grey-background')
  }
  if (hasDarkBackground) {
    sectionClasses.push('has-background-dark has-text-white')
  }
  return (
    <section className={sectionClasses.join(' ')}>
      <div className="container">
        <div className="columns is-vcentered is-multiline">
          <div className="column is-12 is-flex is-horizontal-center">
            <figure className="image is-140px-wide">
              <img
                src={
                  !!image.src.childImageSharp
                    ? image.src.childImageSharp.fluid.src
                    : image.src
                }
                alt={image.alt}
              />
            </figure>
          </div>
          <div className="column is-12 has-text-centered mt-50-touch mt-0-touch">
            <h1>{title}</h1>
            <ShortHorizontalLine
              className="mt-4 mb-20 mb-70-touch"
              isCentered
            />
            {subtitle && <h3 className="mb-4">{subtitle}</h3>}
          </div>
        </div>
      </div>
    </section>
  )
}

MainHero.propTypes = {
  hasGreyBackground: PropTypes.bool,
  hasDarkBackground: PropTypes.bool,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default MainHero
