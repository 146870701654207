import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../Layout'

import HowItWorks from '../organisms/HowItWorks/HowItWorks'
import MainHero from '../organisms/MainHero/MainHero'
import StayInTouchSection from '../organisms/StayInTouchSection/StayInTouchSection'

export const HowItWorksTemplate = ({
  title,
  image,
  items,
  smallPoints,
  verticalSlides,
}) => {
  return (
    <div>
      <MainHero title={title} image={image} hasGreyBackground />
      <HowItWorks
        items={items}
        smallPoints={smallPoints}
        verticalSlides={verticalSlides}
      />
      <StayInTouchSection
        title="Stay in touch"
        description="We’d love to hear from you! Connect with us to learn more, join our mailing list, or inquire about press and partnerships."
      />
    </div>
  )
}

HowItWorksTemplate.propTypes = {
  firstSection: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.shape({
      src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      alt: PropTypes.string,
    }),
  }),
  items: PropTypes.array,
  verticalSlides: PropTypes.array,
  smallPoints: PropTypes.array,
}

const HowItWorksPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <HowItWorksTemplate
        image={frontmatter.firstSection.image}
        title={frontmatter.firstSection.title}
        items={frontmatter.items}
        smallPoints={frontmatter.smallPoints}
        verticalSlides={frontmatter.verticalSlides}
      />
    </Layout>
  )
}

HowItWorksPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default HowItWorksPage

export const pageQuery = graphql`
  query HowItWorksTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "how-it-works-page" } }) {
      frontmatter {
        firstSection {
          title
          image {
            src {
              childImageSharp {
                fluid(maxWidth: 340, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
        items {
          title
          description
          image {
            src {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
        smallPoints {
          title
          description
          image {
            src {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
        verticalSlides {
          title
          description
          image {
            src {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
      }
    }
  }
`
