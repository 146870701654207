import React, { useState } from 'react'
import PropTypes from 'prop-types'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import Button from '../../atoms/Button/Button'
import Checkbox from '../../atoms/Checkbox/Checkbox'

function validateEmail(email) {
  const validationRegex = /\S+@\S+\.\S+/
  return validationRegex.test(email)
}

const StayInTouchSection = ({
  title,
  description,
  tag,
  emailUpdatesConsent,
}) => {
  const [isEmailValid, setEmailValid] = useState(true)
  const [isEmailTouched, setEmailTouched] = useState(false)
  const [isSubmitted, setSubmitted] = useState(false)
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [allowMarketing, setAllowMarketing] = useState(false)

  return (
    <section
      className="section is-medium has-background-dark has-text-white"
      id="contact-us"
    >
      <div className="container">
        <div className="columns">
          <div className="column is-10-tablet is-offset-1-tablet is-12-mobile">
            <div className="columns is-multiline">
              <div className="columns is-gapless is-multiline">
                <form>
                  <div className="column is-12">
                    <h2 className="mb-4">{title}</h2>
                    {description && (
                      <p className="body-large mb-1">{description}</p>
                    )}
                  </div>
                  {!isSubmitted ? (
                    <>
                      <div className="column is-6 py-0">
                        <div className="field">
                          <label className="label body-small has-text-weight-bold has-text-white mb-4">
                            Email address
                          </label>
                          <div className="control">
                            <input
                              type="text"
                              placeholder="Enter email address"
                              className="input body-small border-radius-23px"
                              onBlur={() => {
                                setEmailValid(validateEmail(email))
                              }}
                              onChange={(e) => {
                                setEmail(e.target.value)
                                if (!isEmailTouched) setEmailTouched(true)
                              }}
                            />
                          </div>
                          <p className="help is-danger body-small">
                            {isEmailValid ? ' ' : 'This email is invalid'}
                          </p>
                        </div>
                      </div>

                      <div className="column is-8 mb-2 mt-5 pt-0">
                        <div className="field">
                          <label className="label body-small has-text-weight-bold has-text-white mb-4">
                            Message (optional)
                          </label>
                          <div className="control">
                            <textarea
                              className="textarea body-small border-radius-10px"
                              placeholder="Enter optional message"
                              onChange={(e) => {
                                if (e.target) {
                                  setMessage(e.target.value)
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="column is-12 my-4">
                        <div className="field">
                          <Checkbox
                            handleChange={(isChecked) => {
                              setAllowMarketing(isChecked)
                            }}
                          >
                            {emailUpdatesConsent ||
                              'Click here to sign up for periodic email updates from Carefully'}
                          </Checkbox>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="has-text-centered mt-6">
                      <div className="contact-us-submitted like-h3">
                        Thanks for reaching out! You'll hear from us soon.
                      </div>
                    </div>
                  )}
                  {!isSubmitted && (
                    <div className="column">
                      <Button
                        disabled={!isEmailValid}
                        handleClick={async (e) => {
                          e.preventDefault()
                          setEmailValid(validateEmail(email))
                          if (!isEmailValid || !isEmailTouched) {
                            return
                          }
                          const payload = {
                            'gdpr[26047]': allowMarketing,
                            gdpr: allowMarketing,
                            MMERGE3: message,
                            allowMarketing,
                            ...(tag ? { MMERGE4: tag } : {}),
                          }
                          const response = await addToMailchimp(email, payload)
                          console.log(response)
                          const hasError =
                            !response ||
                            !response.result ||
                            response.result === 'error'
                          const isAlreadySubscribed =
                            response.result &&
                            response.result.includes(
                              'already subscribed to list'
                            )
                          setSubmitted(true)
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

StayInTouchSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  emailUpdatesConsent: PropTypes.string,
  tag: PropTypes.string,
}

export default StayInTouchSection
